import React, { useState } from "react";
import { logos, socialMediaUrl } from "../../data";
import { Link, Events } from "react-scroll";
import "./Header.css";
import { useEffect } from "react";

import text_constants from "../../constants/constants";


function Header() {
    const { linkdein, github, facebook } = socialMediaUrl;
    const [isOpen, setIsOpen] = useState(false);


    useEffect(() => {

        // Registering the 'begin' event and logging it to the console when triggered.
        Events.scrollEvent.register('begin', (to, element) => {
            console.log('begin', to, element);
        });

        // Registering the 'end' event and logging it to the console when triggered.
        Events.scrollEvent.register('end', (to, element) => {
            console.log('end', to, element);
        });


        // Returning a cleanup function to remove the registered events when the component unmounts.
        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);

    const toggleClass = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);

    };

    return (
        <header className="z-10 block container top-0 fixed mx-auto md:flex justify-between bg-white dark:bg-dark-mode py-2 px-10 max-w-[100%]">
            <div className="flex justify-between items-center py-2 md:py-10">
                <Link offset={-130.5}
                    spy={true}
                    activeClass="active-link"
                    smooth={true}
                    duration={800}
                    to="home_page"
                >
                    <img className="w-14" src={logos.logogradient} alt="logo" />
                </Link>
                <div onClick={(e) => toggleClass(e)} className="cursor-pointer">
                    <svg
                        className="stroke-dark-heading dark:stroke-white md:hidden"
                        width="25"
                        height="20"
                        viewBox="0 0 16 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1.4375 1.3125H14.5625M1.4375 11.3125H14.5625H1.4375ZM1.4375 6.3125H14.5625H1.4375Z"
                            strokeWidth="1.875"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </div>
            <nav className={`${isOpen ? 'sm:hidden max-sm:hidden' : ''} text-center md:flex justify-between`}>
                <ul className="dark:text-light-content font-medium md:flex items-center md:space-x-5 md:mr-10">
                    <li className="pb-1 md:pb-0">
                        <Link offset={-130.5} spy={true} activeClass="active-link" smooth={true} duration={800} to="home_page">
                            {text_constants.home}
                        </Link>
                    </li>
                    <li className="pb-1 md:pb-0">
                        <Link offset={-130.5} spy={true} activeClass="active-link" smooth={true} duration={800} to="about_page" >
                            {text_constants.about}

                        </Link>
                    </li>
                    <li className="pb-1 md:pb-0">
                        <Link offset={-130.03} spy={true} activeClass="active-link" smooth={true} duration={800} to="technologies_page" >
                            {text_constants.technologies}
                        </Link>
                    </li>
                    <li className="pb-1 md:pb-0">
                        <Link offset={-130.03} spy={true} activeClass="active-link" smooth={true} duration={800} to="projects_page" >
                            {text_constants.projects}
                        </Link>
                    </li>
                    <li className="pb-1 md:pb-0">
                        <Link offset={-130.03} spy={true} activeClass="active-link" smooth={true} duration={800} to="contact_page" >
                            {text_constants.contact}
                        </Link>
                    </li>
                </ul>
                <ul className="flex justify-evenly items-center my-5 md:my-0 md:space-x-5 md:mr-5">
                    <li>
                        <a href={facebook} target="_blank" rel="noreferrer noopener">
                            <svg className="dark:fill-light-heading fill-dark-heading" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 0C6.72871 0 0 6.72886 0 14.9997C0 23.2709 6.72871 30 15 30C23.2708 30 30 23.2709 30 14.9997C30 6.72886 23.2708 0 15 0ZM19.2394 9.46903C19.2394 9.54006 19.2112 9.60818 19.161 9.6584C19.1108 9.70862 19.0426 9.73684 18.9716 9.73684L17.2665 9.73791C16.1414 9.73791 15.9357 10.176 15.9357 11.0389V12.8289H18.8602C18.9371 12.8289 19.0104 12.8621 19.0611 12.92C19.1117 12.9778 19.1352 13.0547 19.1259 13.131L18.7094 16.3535C18.7011 16.418 18.6695 16.4774 18.6206 16.5203C18.5717 16.5633 18.5089 16.587 18.4438 16.587H15.9357V24.5887C15.9357 24.6598 15.9075 24.7279 15.8573 24.7781C15.8071 24.8283 15.7389 24.8565 15.6679 24.8565H12.3407C12.2696 24.8565 12.2015 24.8283 12.1513 24.7781C12.1011 24.7279 12.0728 24.6598 12.0728 24.5887V16.587H9.55677C9.48575 16.587 9.41763 16.5588 9.3674 16.5086C9.31718 16.4583 9.28896 16.3902 9.28896 16.3192V13.0967C9.28896 13.0257 9.31718 12.9576 9.3674 12.9073C9.41763 12.8571 9.48575 12.8289 9.55677 12.8289H12.0728V10.7199C12.0728 7.92675 13.7633 6.19191 16.485 6.19191C17.6551 6.19191 18.6891 6.27894 19.0067 6.32099C19.0711 6.3295 19.1302 6.3611 19.173 6.40992C19.2158 6.45874 19.2394 6.52145 19.2394 6.58638V9.46903Z" />
                            </svg>

                        </a>
                    </li>
                    <li>
                        <a href={linkdein} target="_blank" rel="noreferrer noopener">
                            <svg
                                className="dark:fill-light-heading fill-dark-heading"
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M15 0.599976C7.04701 0.599976 0.600006 7.04698 0.600006 15C0.600006 22.953 7.04701 29.4 15 29.4C22.953 29.4 29.4 22.953 29.4 15C29.4 7.04698 22.953 0.599976 15 0.599976ZM11.475 20.9685H8.55901V11.5845H11.475V20.9685ZM9.99901 10.4325C9.07801 10.4325 8.48251 9.77997 8.48251 8.97297C8.48251 8.14948 9.09601 7.51648 10.0365 7.51648C10.977 7.51648 11.553 8.14948 11.571 8.97297C11.571 9.77997 10.977 10.4325 9.99901 10.4325ZM22.125 20.9685H19.209V15.768C19.209 14.5575 18.786 13.7355 17.7315 13.7355C16.926 13.7355 16.4475 14.292 16.236 14.8275C16.158 15.018 16.1385 15.288 16.1385 15.5565V20.967H13.221V14.577C13.221 13.4055 13.1835 12.426 13.1445 11.583H15.678L15.8115 12.8865H15.87C16.254 12.2745 17.1945 11.3715 18.768 11.3715C20.6865 11.3715 22.125 12.657 22.125 15.42V20.9685Z" />
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href={github} target="_blank" rel="noreferrer noopener">
                            <svg
                                className="dark:fill-light-heading fill-dark-heading"
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M15 0C6.7125 0 0 6.7125 0 15C0 21.6375 4.29375 27.2437 10.2563 29.2313C11.0063 29.3625 11.2875 28.9125 11.2875 28.5188C11.2875 28.1625 11.2688 26.9813 11.2688 25.725C7.5 26.4188 6.525 24.8062 6.225 23.9625C6.05625 23.5312 5.325 22.2 4.6875 21.8438C4.1625 21.5625 3.4125 20.8687 4.66875 20.85C5.85 20.8313 6.69375 21.9375 6.975 22.3875C8.325 24.6562 10.4812 24.0187 11.3438 23.625C11.475 22.65 11.8688 21.9937 12.3 21.6187C8.9625 21.2437 5.475 19.95 5.475 14.2125C5.475 12.5813 6.05625 11.2313 7.0125 10.1813C6.8625 9.80625 6.3375 8.26875 7.1625 6.20625C7.1625 6.20625 8.41875 5.8125 11.2875 7.74375C12.4875 7.40625 13.7625 7.2375 15.0375 7.2375C16.3125 7.2375 17.5875 7.40625 18.7875 7.74375C21.6562 5.79375 22.9125 6.20625 22.9125 6.20625C23.7375 8.26875 23.2125 9.80625 23.0625 10.1813C24.0188 11.2313 24.6 12.5625 24.6 14.2125C24.6 19.9688 21.0938 21.2437 17.7563 21.6187C18.3 22.0875 18.7688 22.9875 18.7688 24.3937C18.7688 26.4 18.75 28.0125 18.75 28.5188C18.75 28.9125 19.0312 29.3813 19.7812 29.2313C22.759 28.2259 25.3465 26.3121 27.1796 23.7592C29.0127 21.2063 29.9991 18.1429 30 15C30 6.7125 23.2875 0 15 0Z"
                                />
                            </svg>
                        </a>
                    </li>
                </ul>
            </nav>
        </header >
    );
}

export default Header;
